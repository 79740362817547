import React from 'react'

export default function MisionVisionLanding() {
  return (
    <div className='overflow-hidden bg-black h-auto py-10 flex flex-col lg:flex-row sm:flex-col md:flex-col pb-10 lg:pb-32 sm:pb-10 md:pb-32' id='vision'>
      <div className='w-full lg:w-1/2 sm:w-full md:w-full'>
        <h2 className='text-indigo-50 font-extrabold text-6xl'>Misión</h2>
        <div className='p-2 lg:p-5 lg:px-32 text-indigo-50'>Crear un ambiente inclusivo y enriquecedor para todos nuestros alumnos, sin distinción de edad, sexo, religión, o cualquier otra índole, en donde se sientan valorados, inspirados y capacitados para así formar músicos competentes que demuestren valores y cuenten con los suficientes recursos y capacidades que el medio demanda, siendo siempre innovadores y proactivos para con la industria musical, comprometidos con la cultura y el arte, así como con el mejoramiento personal y social.</div>
      </div>
      <div className='w-full lg:w-1/2 sm:w-full md:w-full'>
        <h2 className='font-extrabold text-6xl text-indigo-50'>Visión</h2>
        <div className='p-2 lg:p-5 lg:px-32 text-indigo-50'>Ser un referente internacional en la educación musical, reconocido por nuestra excelencia académica, nuestro compromiso con el bienestar emocional y el crecimiento personal de nuestros alumnos, maestros y colaboradores, así como por nuestra contribución continua al desarrollo cultural y artístico de la sociedad, siendo así un pilar importante para demostrar que el arte es la llave para un mejor porvenir en donde la empatía, la sensibilidad y el amor sean un común denominador en las generaciones venideras. </div>
      </div>
    </div>
  )
}
