import React, { useState } from 'react'
import 'animate.css';
import Sparkles from './components/Sparkle';
import { IoIosArrowDown } from "react-icons/io";
import ChildrenTabs from './components/ChildrenTabs';
import '../../static/styles/Landing.css';


export default function AnimatedLanding() {
    const [childrenModal, setChildrenModal] = useState(false);
    const [tabNumber, setTabNumber] = useState(0);
    return (
        <div className='w-full bg-gradient-to-b from-black to-black h-full text-white'>

            <div className='relative w-full h-[110vh] flex items-center justify-center'>
                {/* <div className='w-full h-[100%] bg-red-50'>
                    <video width="100%" autoPlay muted loops> 
                        <source src={require('../../static/images/VideoLanding.mp4')} type="video/mp4" />
                        Tu navegador no soporta la etiqueta de video.
                    </video>
                </div> */}
                <Sparkles children={<div className='animate__animated animate__zoomIn lg:text-9xl text-6xl '>Larghetto</div>}></Sparkles>
                <div class="absolute bottom-40 lg:bottom-20 animate__animated animate__bounce animate__infinite animate__slow flex flex-col items-center justify-center">
                    <div>
                        Baja para conocer más
                    </div>
                    <IoIosArrowDown />
                </div>
            </div>
            
            {/* <div className='w-full h-[100vh] relative  flex flex-col items-center justify-center'>
                <div className={`absolute w-[100vw] h-[100%] bg-[#0a0a0a] border-red-500 border-0 border-solid z-10 p-3 flex-col ${childrenModal ? "flex" : "hidden"}`}>
                    <div className='absolute right-0 top-0 bg-red-800 cursor-pointer' onClick={() => setChildrenModal(false)}>
                        Salir
                    </div>
                    <div className='w-full h-[10%]'>
                        <ChildrenTabs setTabNumber={setTabNumber} />
                    </div>
                    
                </div>
                Quote
                <div className='top-10 left-0 absolute lg:left-20 sm:left-0' >
                    <div className='text-2xl'>
                        "La música es la puerta secreta del alma."
                    </div>
                    <div>
                        - Ludwig van Beethoven
                    </div>
                </div>
                <div className={`relative text-4xl mb-2`}>
                    La música es para todas las edades.
                </div>
                <div className='w-full h-[70%] flex flex-row gap-10'>
                    <div className='group w-1/2 h-ful flex flex-col justify-center items-center relative'>
                        Adultos  <div className='text-xs text-slate-400'>18+ años</div>
                        <div className='group absolute top-1/4 left-1/4 hidden group-hover:block cursor-pointer' onClick={() => setChildrenModal(true)}>
                            <Sparkles children={<div className='h-[40vh] w-[20vw]'></div>}></Sparkles>
                        </div>
                        <div className='h-[80%] w-[50%] flex items-center justify-center transition-transform duration-300 group-hover:scale-110 cursor-pointer'>
                            <img src={require('../../static/images/kidIcon.png')} alt="" srcset="" className='h-[100%] w-[90%]' />
                        </div>
                    </div>
                    <div className='group w-1/2 h-ful flex flex-col justify-center items-center relative'>
                        Adultos  <div className='text-xs text-slate-400 '>18+ años</div>
                        <div className='group absolute top-1/4 left-1/4 hidden group-hover:block cursor-pointer'>
                            <Sparkles children={<div className='h-[40vh] w-[20vw]'></div>}></Sparkles>
                        </div>
                        <div className='h-[80%] w-[50%] flex items-center justify-center transition-transform duration-300 group-hover:scale-110 cursor-pointer'>
                            <img src={require('../../static/images/adultIcon.png')} alt="" srcset="" className='h-[75%] w-[60%]' />
                        </div>

                    </div>
                </div>

            </div> */}
{/*             
            <div className='w-full h-[100vh] relative  flex flex-col items-center justify-center'>
                <div className={`w-full h-[90%] flex-row flex`}>
                    <div className='relative w-[40%] h-full flex flex-col gap-10 justify-center items-center '>
                        <div className='relative p-4 font-semibold text-xl'>
                            <div className='absolute -top-12 left-[35%] text-5xl font-semibold text-orange-600'>Atención</div>
                            <div className='absolute -bottom-16 left-[38%] text-3xl font-semibold text-lime-500'>Inteligencia</div>
                            La música es una gimnasia cerebral que estimula el lenguaje, la memoria, la atención y las habilidades espaciales.
                        </div>
                        <div className='absolute top-24 left-10 rotate-12 text-2xl font-bold text-amber-500'>Lenguaje</div>
                        <div className='absolute top-24 right-10 -rotate-12 text-2xl font-bold text-sky-300'>Desarrollo</div>
                        <div className='absolute bottom-24 left-10 rotate-12 text-2xl font-bold text-rose-600'>Memoria</div>
                        <div className='absolute bottom-24 right-10 -rotate-12 text-2xl font-bold text-violet-500'>Autoestima</div>
                    </div>
                    <div className='w-[60%] h-full flex items-center justify-center'>
                        <img src={require('../../static/images/KidBrain.jpg')} alt="" srcset="" className='w-[70%] h-[70%] imageGlow rounded-2xl' />
                    </div>
                </div>
            </div>
            <div className='w-full h-[100vh] relative  flex flex-col items-center justify-center'>
                <div className={`w-full h-[90%] flex-row flex`}>
                    <div className='w-[60%] h-full flex items-center justify-center'>
                        <img src={require('../../static/images/KidCreativity.jpg')} alt="" srcset="" className='w-[70%] h-[70%] imageGlow rounded-2xl' />
                    </div>
                    <div className='relative w-[40%] h-full flex flex-col gap-10 justify-center items-center '>
                        <div className='p-4 relative'>
                            <div className='absolute -top-12 left-[35%] text-5xl font-semibold text-orange-600'>Creatividad</div>
                            <div className='absolute -bottom-16 left-[42%] text-3xl font-semibold text-lime-500'>Improvisación</div>
                            La música es un lienzo en blanco para la expresión personal. A través de ella, los niños exploran su imaginación, improvisan, componen y tocan con otros, desarrollando su creatividad y confianza en sí mismos.
                        </div>
                        <div className='absolute top-24 left-10 rotate-12 text-2xl font-bold text-amber-500'>Expresión</div>
                        <div className='absolute top-24 right-10 -rotate-12 text-2xl font-bold text-sky-300'>Composición</div>
                        <div className='absolute bottom-24 left-10 rotate-12 text-2xl font-bold text-rose-600'>Imaginación</div>
                        <div className='absolute bottom-24 right-10 -rotate-12 text-2xl font-bold text-violet-500'>Innovación</div>
                    </div>
                </div>
            </div>
            <div className='w-full h-[100vh] relative  flex flex-col items-center justify-center'>
                <div className={`w-full h-[90%] flex-row flex`}>
                    <div className='w-[40%] h-full flex flex-col gap-10 justify-center items-center relative'>
                        <div className='p-4 relative'>
                            <div className='absolute -top-12 left-[35%] text-5xl font-semibold text-orange-600'>Respeto</div>
                            <div className='absolute -bottom-16 left-[42%] text-3xl font-semibold text-lime-500'>Liderazgo</div>
                            La música fomenta la creación de amigos y el trabajo en equipo. Tocar en una banda o cantar en un coro enseña a los niños a comunicarse, respetar las diferencias y trabajar juntos para lograr un objetivo común.
                        </div>
                        <div className='absolute top-24 left-10 rotate-12 text-2xl font-bold text-amber-500'>Comunicación</div>
                        <div className='absolute top-24 right-10 -rotate-12 text-2xl font-bold text-sky-300'>Amigos</div>
                        <div className='absolute bottom-24 left-10 rotate-12 text-2xl font-bold text-rose-600'>Colaboración</div>
                        <div className='absolute bottom-24 right-10 -rotate-12 text-2xl font-bold text-violet-500'>Cultura</div>
                    </div>
                    <div className='w-[60%] h-full flex items-center justify-center'>
                        <img src={require('../../static/images/KidConvivir.jpg')} alt="" srcset="" className='w-[70%] h-[70%] imageGlow rounded-2xl' />
                    </div>
                </div>
            </div> */}

        </div>
    )
}
