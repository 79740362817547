import React, { useState } from 'react';
import Hero from './components/Hero';
import FeatureSectionBUgambilias from './components/FeatureSectionBugambilias';
import FeatureSectionCentro from './components/FeatureSectionCentro';
import FeatureSectionCuautla from './components/FeatureSectionCuautla';
import NavBar from '../../shared/NavBar';
import OfertaEducativa from './components/OfertaEducativa';
import FeatureSectionCoyoacan from './components/FeatureSectionCoyoacan';
import FeatureSectionBUgambiliasCopy from './components/FeatureSectionBugambilias copy';
import FeatureSectionCentroCopy from './components/FeatureSectionCentro copy';
import FeatureSectionCuautlaCopy from './components/FeatureSectionCuautla copy';
import FeatureSectionCoyoacanCopy from './components/FeatureSectionCoyoacan copy';
import MisionVision from '../about/components/MisionVision';
import MisionVisionLanding from './components/MisionVision';
import QuienesSomosLanding from './components/QuienesSomosLanding';


const LandingPage = () => {
    return (
        <div className='relative'>
            <Hero className='scroll-smooth' />
            <OfertaEducativa/>
            {/* <FeatureSectionBUgambilias className='scroll-smooth' /> */}
            <FeatureSectionBUgambiliasCopy/>
            {/* <FeatureSectionCentro className='scroll-smooth' /> */}
            <FeatureSectionCentroCopy className='scroll-smooth' />
            {/* <FeatureSectionCuautla className='scroll-smooth' /> */}
            <FeatureSectionCuautlaCopy className='scroll-smooth' />
            {/* <FeatureSectionCoyoacan/> */}
            <FeatureSectionCoyoacanCopy/>
            <QuienesSomosLanding/>
            <MisionVisionLanding/>
            <a
                href="https://wa.me/+525521048568"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={require('../../static/images/whatsapp.png')} alt="" srcset="" className='w-[7vh] h-[7vh] bg-[#1BD741] fixed bottom-0 left-0 rounded-full m-3 p-0.5 cursor-pointer transition-transform duration-300 hover:scale-110' />
            </a>
        </div>
    );
};



export default LandingPage;
