import React from 'react'

export default function QuienesSomosLanding() {
  return (
    <div className='overflow-hidden bg-black h-auto py-10 flex flex-col lg:flex-row sm:flex-col md:flex-col pb-10 lg:pb-32 sm:pb-10 md:pb-32 justify-center' id='vision'>
      <div className='w-full sm:w-full md:w-full lg:w-4/6 px-3'>
        <h2 className='text-indigo-50 font-extrabold text-6xl'>¿Quiénes Somos?</h2>
        <div className='p-2 lg:p-5 lg:px-32 text-indigo-50'>Somos una escuela de música comprometida con la excelencia académica, regida bajo la creencia de que nuestros alumnos, maestros y personal en general, son y serán siempre nuestra prioridad y por ello nos enfocamos en fomentar el aprendizaje y crecimiento personal de cada uno de ellos, en espacios idóneamente acondicionados, a través del respeto, humildad, empatía, y un profundo amor por la música, la cultura y el arte, entendiendo que el objetivo principal de esta empresa es el mejoramiento social a través del arte y sus artistas, es por ello que cada uno de los miembros del personal de Larghetto sabemos perfectamente que la nota perfecta es aquella que nutre el alma y no aquella que suena bajo la presión del perfeccionismo dañino e inalcanzable.</div>
      </div>
    </div>
  )
}
